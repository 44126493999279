import React, { Component } from 'react'
import { Link } from 'react-router-dom';


class Services extends Component {

  render() {
    return (
      <div>
        <div className="text-center partial-01">
          <div className="flex-cont">
            <div className="flex-col">
              <div className="card p-2 card-service">
                <div className="card-body">
                  <div className="mb-3"><i className="fas fa-database fa-8x"></i></div>
                  <span className="flex-hdr-text">Data</span>
                  <div className="container-03">
                    <p className="card-text">We install backup solutions for individuals and small businesses.</p>
                  </div>
                  {/* <!-- Button trigger modal --> */}
                  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalBackups">
                    Details
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-col">
              <div className="card p-2 card-service">
                <div className="card-body">
                  <div className="mb-3 space-blue"><i className="fas fa-wifi fa-8x"></i></div>
                  <span className="flex-hdr-text">Network</span>
                  <div className="container-03">
                    <p className="card-text">We will install, setup and configure your old or new network.</p>
                  </div>
                  {/* <!-- Button trigger modal --> */}
                  <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#modalNetwork">
                    Details
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-col">
              <div className="card p-2 card-service">
                <div className="card-body">
                  <div className="mb-3 space-red"><i className="fas fa-medkit fa-8x"></i></div>
                  <span className="flex-hdr-text">Help</span>
                  <div className="container-03">
                    <p className="card-text">Having technical issues? Contact us and we will respond asap.</p>
                  </div>
                  <Link href="/help" to="/help" className="btn btn-primary">Details</Link>
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* <!-- Modal Networks Backup--> */}
        <div className="modal fade" id="modalBackups" tabIndex="-1" role="dialog" aria-labelledby="modalBackupsLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title space-dark modal-title-02" id="modalBackupsLabel">Data Backup</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="modal-col-left div-center-02">
                  <div className=""><i className="space-light fas fa-database fa-4x"></i></div>
                </div>
                <div className="modal-col-right">
                  <div className="div-center-02">
                    <ul className="space-dark text-left">
                      <li>Available Solutions:</li>
                      <li className="ml-2">&#8226; Home Backup</li>
                      <li className="ml-2">&#8226; Business Backup</li>
                      <li className="ml-2">&#8226; Online Backup</li>
                      <li className="ml-2">&#8226; Offline Backup</li>
                    </ul>
                  </div>
                </div>
                <div className="space-dark modal-bottom">We will ensure that your data is protected and preserved and that you can get back up and running in a matter of minutes after suffering a catastrophic event.</div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Modal Network--> */}
        <div className="modal fade" id="modalNetwork" tabIndex="-1" role="dialog" aria-labelledby="modalNetworkLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title space-dark modal-title-02" id="modalNetworkLabel">Network</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="modal-col-left div-center-02">
                  <div className="space-blue"><i className="fas fa-wifi fa-4x"></i></div>
                </div>
                <div className="modal-col-right">
                  <div className="div-center-02">
                    <ul className="space-dark text-left">
                      <li>Available Solutions:</li>
                      <li className="ml-2">&#8226; Wired Networks</li>
                      <li className="ml-2">&#8226; Wireless Networks</li>
                      <li className="ml-2">&#8226; Network Cabling</li>
                      <li className="ml-2">&#8226; Remote Access</li>
                    </ul>
                  </div>
                </div>
                <div className="space-dark modal-bottom">We’ll custom design your network infrastructure to meet the needs of your business today and grow with your business to achieve your future goals.</div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

      </div >


    )
  }
}

export default Services;
