import React, { Component } from 'react'

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year: Number,
      email: '',
      message: '',
      isSubmitted: false,
      fetchError: null
    }
  }

  componentWillMount() {
    let d = new Date();
    let currentYear = d.getFullYear();
    this.setState({
      year: currentYear
    });
  }
  handleSubmit = (e) => {
    e.preventDefault();

    let site_origin = 'space47.com';

    fetch('https://api47.com/contact_form', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.state.email,
        message: this.state.message,
        site_origin
      })
    })
      .then((res) => {
        if (res.status === 429) {
          console.log('Response was 429. Too many requests.');
        }
        this.setState({
          isSubmitted: true
        })
      })
      .catch(err => {
        if (err) {
          console.log(err);
          this.setState({
            fetchError: 'There was an issue sending your message.'
          });
        }
      });
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  render() {
    const { isSubmitted, email, message, fetchError } = this.state;
    return (
      <div className="flex-grow-1 main text-center form-width">
        {fetchError ? (
          <div>
            <div className="flex-cont space-dark">
              <div className="flex-col">
                <div className="card p-2">
                  <div className="card-body">
                    <div className="mb-3 space-red"><i className="fas fa-exclamation-triangle fa-8x"></i></div>
                    <p className="flex-hdr-text">Sending Failed</p>
                    <div className="container-03">
                      <h4 className="card-text mb-3">{fetchError}</h4>
                      <h4 className="card-text mb-3">Please try again later.</h4>
                      <span>Your email: </span>
                      <span>{email}</span>
                      <br />
                      <span className="mt-3">Your message: </span>
                      <span>{message}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>) : (<div>{isSubmitted ? (
            <div>
              <div className="flex-cont space-dark">
                <div className="flex-col">
                  <div className="card p-2">
                    <div className="card-body">
                      <div className="mb-3 space-green"><i className="fas fa-check-square fa-8x"></i></div>
                      <p className="flex-hdr-text">Message Received</p>
                      <div className="container-03">
                        <h4 className="card-text mb-3">We will contact you shortly.</h4>
                        <span>Your email: </span>
                        <span>{email}</span>
                        <br />
                        <span className="mt-3">Your message: </span>
                        <span>{message}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (<div>        <div>
            <h1 className="text-center mb-4 pt-3">Contact Us</h1>
          </div>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group invis">
                <input onChange={this.onChange} name="name1" type="text" className="form-control form-input-text" id="inputName1" aria-describedby="nameHelp" placeholder="Enter Name" />
              </div>
              <div className="form-group">
                <input onChange={this.onChange} name="email" type="email" className="form-control form-input-text" id="inputEmail" aria-describedby="emailHelp" placeholder="Enter Email" required />
              </div>
              <div className="form-group">
                <textarea onChange={this.onChange} name="message" className="form-control form-input-text" id="formControlTextarea1" rows="5" placeholder="Message" required></textarea>
                <div className="text-center div-center-03 mt-2">
                  <button type="submit" className="btn btn-primary mt-3 text-center">Submit</button>
                </div>
              </div>
            </form>
          </div>)}</div>)}
      </div>
    )
  }
}

export default Contact;
