import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import logo from '../img/space47-logo.png';

class Help extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year: Number
    }
  }

  componentWillMount() {
    let d = new Date();
    let currentYear = d.getFullYear();
    this.setState({
      year: currentYear
    });
  }

  render() {
    return (
      <div className="flex-grow-1 main text-center">
        <Link href="/" to="/">
          <img className="logo-small img-shrink" src={logo} alt="Space 47" />
        </Link>
        <p>We use TeamViewer to assist you more easily.</p>
        <a className="text-dec-none" href="https://get.teamviewer.com/space47_quick_support" target="_blank" rel="noopener noreferrer">
          <p className="btn01 img-shrink">Download TeamViewer</p>
        </a>
        <div className="div-center">
          <ul className="text-left lists">
            <li>1. Download TeamViewer</li>
            <li>2. Run TeamViewer</li>
            <li>3. Contact Us</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default Help;
