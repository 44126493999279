import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import isEmpty from './validation/is-empty';

import './css/styles.css';

import Nav from "./components/Nav";
import Footer from "./components/Footer";
import Landing from './components/Landing';
import Landing02 from './components/Landing02';
import Help from './components/Help';
import Contact from './components/Contact';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      errors: null
    }
  }

  fetchTip() {
    let serverName = 'api47.com';
    let errors = [];
    fetch('https://' + serverName + '/techtips/single')
      .then(res => {
        let status = res.status;
        if (status === 429) {
          return {
            // tip: 'Too many requests'
          }
        } else if (status === 200) {
          return res.json();
        } else {
          return {};
        }
      })
      .then(data => {
        if (!isEmpty(data)) {
          this.setState({ data });
        } else {
          errors.push('Error: Data empty');
          this.setState({ errors });
        }
      })
      .catch(err => {
        errors.push('Error: Failed to fetch.');
        errors.push('Error #2');
        this.setState({ errors });
      });
  }

  componentDidMount() {
    this.fetchTip();

    // Only run 
    let counter = 0;
    let maxIntervalFetches = 5;
    this.interval = setInterval(() => {
      if (counter > maxIntervalFetches) {
        clearInterval(this.interval);
      } else {
        counter++;
        this.fetchTip();
      }
    }, 20000);

  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    let { data, errors } = this.state;

    return (
      <Router>
        <div className="h-100">
          <div id="content" className="d-flex flex-column h-100">
            <Nav />
            <Switch>
              {/* https://tylermcginnis.com/react-router-pass-props-to-components/ */}
              <Route exact path="/" render={(props) => <Landing {...props} data={data} errors={errors} />} />
              <Route exact path="/help" component={Help} />
              <Route exact path="/contact" component={Contact} />
              <Route path="/" component={Landing02} />
            </Switch>
            <Footer />
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
