import React, { Component } from 'react'
import isEmpty from '../../validation/is-empty';


class TechTips extends Component {

  render() {
    let { data } = this.props;
    let techTip = '';
    let textAlign = 'container-03';
    if (isEmpty(data)) {
    } else {
      if (data === 'Failed to fetch.') {
      } else {
        techTip = data.tip;
        if (techTip.length > 33) {
          textAlign = 'container-04';
        }
      }
    }
    return (
      <div>
        {
          techTip.length > 0 ? (
            <div className="text-center partial-01 box-540h">
              <div className="flex-cont flex-med-box">
                <div className="flex-col">
                  <div className="card p-2">
                    <div className="card-body">
                      <div className="mb-3 space-yellow"><i className="far fa-lightbulb fa-8x"></i></div>
                      <span className="flex-hdr-text">Tech Tips</span>
                      <div className={textAlign}>
                        <span className="mb-3 space-yellow quote-left"><i className="fas fa-quote-left"></i></span>
                        <span className="card-text quote-text">{techTip}</span>
                        <span className="mb-3 space-yellow quote-right"><i className="fas fa-quote-right fa-1x"></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (<div className="text-center partial-02 spacer-01"></div>)
        }
      </div>
    )
  }
}

export default TechTips;
