import React, { Component } from 'react'

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year: Number
    }
  }

  componentWillMount() {
    let d = new Date();
    let currentYear = d.getFullYear();
    this.setState({
      year: currentYear
    });
  }
  // https://stackoverflow.com/questions/50674837/flush-footer-to-the-bottom-of-the-page-in-bootstrap-4/50678696
  render() {
    return (
      <footer id="footer" className="p-2">
        <div>
          <div className='text-center'>Copyright &copy; {this.state.year} Space 47</div>
        </div>
      </footer>
    )
  }
}

export default Footer;
