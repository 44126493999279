import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import logo from '../img/space47-logo.png';
import Services from "./partials/Services";
import TechTips from "./partials/TechTips";

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      year: Number
    }
  }
  componentWillMount() {
    let d = new Date();
    let currentYear = d.getFullYear();

    this.setState({
      year: currentYear
    });
  }
  render() {
    let { data, errors } = this.props;

    return (
      <div className="flex-grow-1 text-center main landing">
        <div className="container-01">
          <Link href="/" to="/" className="no-underline">
            <img className="img-center img-shrink" src={logo} alt="Space 47" />
            <div className="text-center site-name-01 lato">
              <span>SPACE 47</span>
            </div>
          </Link>
          <h1 className="h1-text">Tech Support</h1>
        </div>
        <Services />
        <Link href="/contact" to="/contact" className="no-underline">
          <div className="full-width-box-01">
            <div className="img-shrink">
              <div className="mb-3 space-white"><i className="fas fa-envelope fa-5x"></i></div>
              <span className="flex-hdr-text space-white">Contact Us</span>
            </div>
          </div>
        </Link>
        <TechTips data={data} errors={errors} />
      </div>
    )
  }
}

export default Landing;