import React, { Component } from 'react'
import { NavLink } from 'react-router-dom';

import logo from '../img/space47-logo.png';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hamToggle: true
    }
  }

  handleClick = (e) => {
    let collapseNav = () => {
      this.setState({ hamToggle: true });
    }
    collapseNav();
  }
  expandNav = (e) => {
    this.setState({ hamToggle: false });
  }

  render() {
    return (
      <div id="content-header" className="nav-container">
        <div className="container">
          <nav id="top-nav" className="navbar navbar-expand-lg navbar-dark">
            <NavLink className="navbar-brand" href="/" to="/">
              <img src={logo} className="logo-main img-shrink" alt="Space 47" />
            </NavLink>
            <NavLink className="navbar-brand title-text" href="/" to="/">
              <div className="text-center">
                <span className="letter" data-letter="S">S</span>
                <span className="letter" data-letter="P">P</span>
                <span className="letter" data-letter="A">A</span>
                <span className="letter" data-letter="C">C</span>
                <span className="letter" data-letter="E">E</span>
                &nbsp;&nbsp;
                <span className="letter" data-letter="4">4</span>
                <span className="letter" data-letter="7">7</span>
              </div>
            </NavLink>
            <button onClick={this.expandNav} className={this.state.hamToggle ? 'navbar-toggler' : 'navbar-toggler collapsed'} type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={this.state.hamToggle} aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={this.state.hamToggle ? 'collapse navbar-collapse' : 'collapse navbar-collapse show'} id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <NavLink onClick={this.handleClick} className="nav-link top-nav-link lato" href="/help" to="/help">Help</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink onClick={this.handleClick} className="nav-link top-nav-link lato" href="/contact" to="/contact">Contact</NavLink>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    )
  }
}

export default Nav;
